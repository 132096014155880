<template>
  <div>
    <delivery-rule-panel />
    <delivery-table />
  </div>
</template>

<script>
import moment from 'moment';
import DeliveryRulePanel from './DeliveryRulePanel.vue';
import DeliveryTable from './DeliveryTable.vue';

export default {
  name: 'DeliveryToday',
  components: { DeliveryTable, DeliveryRulePanel },
  async mounted() {
    await this.$store.dispatch('FETCH_DELIVERY', { date: moment(Date.now()).format('YYYY-MM-DD') });
  },
};
</script>
