<template>
  <div class="delivery-table">
    <el-table
      ref="multipleTable"
      :data="delivery"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column property="client" label="Клиент">
        <template slot-scope="{ row }">
          <router-link :to="`/client/${row.client_id}/info`">
            {{ row.client }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="Блюда">
        <template slot-scope="scope">
          <el-select :value="`Кол-во ${scope.row.meals.length}`">
            <el-option
              v-for="item in scope.row.meals"
              :key="item.key"
              :label="item.name"
              :value="item.name"
            >
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.quantity }}</span>
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column property="address" label="Адрес" />
      <el-table-column property="comment" label="Комментарий" />
      <el-table-column property="address" label="Статус">
        <template slot-scope="scope">
          <el-tag
            :type="
              scope.row.state === 'complete'
                ? 'success'
                : scope.row.state === 'process'
                ? 'warning'
                : 'danger'
            "
            disable-transitions
          >
            {{
              scope.row.state === 'complete'
                ? 'Доставлено'
                : scope.row.state === 'process'
                ? 'На доставке'
                : 'Не доставлено'
            }}
          </el-tag>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DeliveryTable',
  data() {
    return {
      multipleSelection: [],
    };
  },
  computed: {
    ...mapGetters({ delivery: 'DELIVERY' }),
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.$store.commit('SET_SELECTED_DELIVERY', val);
    },
  },
};
</script>
