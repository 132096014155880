<template>
  <div class="kitchen-rule-panel mb-4 mt-2">
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="is-flex">
          <el-select v-model="value" placeholder="Состояние" :disabled="!selected.length">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-button
            class="ml-4"
            type="success"
            icon="el-icon-check"
            :disabled="!selected.length"
            @click="updateDelivery"
          >
            Изменить состояние
          </el-button>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="is-flex is-justify-content-flex-end">
          <el-button type="primary" icon="el-icon-refresh"> Получить новые доставки </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DeliveryRulePanel',
  data() {
    return {
      value: '',
      options: [
        { value: 'complete', label: 'Доставлено' },
        { value: 'process', label: 'На доставке' },
        { value: 'not', label: 'Не доставлено' },
      ],
    };
  },
  computed: {
    ...mapGetters({ selected: 'SELECTED_DELIVERY' }),
  },
  methods: {
    async fetchDelivery() {
      await this.$store.dispatch('FETCH_DELIVERY');
    },
    async updateDelivery() {
      const data = {
        ids: this.selected.map((v) => v.id),
        ...this.selected.map((v) => v.date),
        state: this.value,
      };
      await this.$store.dispatch('UPDATE_DELIVERY', data);
    },
  },
};
</script>
