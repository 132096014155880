<template>
  <el-tabs v-model="activeTab" @tab-click="handleClick">
    <el-tab-pane :label="todayLabel" name="today" lazy>
      <delivery-today v-if="activeTab === 'today'" />
    </el-tab-pane>
    <el-tab-pane :label="tomorrowLabel" name="tomorrow" lazy>
      <delivery-tomorrow v-if="activeTab === 'tomorrow'" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import moment from 'moment';
import DeliveryToday from './DeliveryToday.vue';
import DeliveryTomorrow from './DeliveryTomorrow.vue';

export default {
  name: 'Delivery',
  components: { DeliveryToday, DeliveryTomorrow },
  data() {
    return {
      activeTab: 'today',
    };
  },
  computed: {
    todayLabel() {
      return moment().locale('ru').format('ll');
    },
    tomorrowLabel() {
      return moment().add(1, 'd').locale('ru').format('ll');
    },
  },
  methods: {
    async handleClick() {
      await this.$store.dispatch('RESET_KITCHENS');
    },
  },
};
</script>
